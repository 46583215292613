/* jshint esversion: 6 */
/* jshint esversion: 8 */
/* jshint esversion: 11 */
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { ApiGet } from "../../../helpers/API/ApiData";
import "../../js/Playlist/playlist.scss";
import Header from "../../Layout/Header";
import Sidebar from "../../Layout/Sidebar";
import { ToastContainer } from "react-toastify";

export default function Video() {
  const [showModalEditProfile, setShowModalEditProfile] = useState(false);
  const [allVideos, setAllVideos] = useState();
  const history = useNavigate();

  useEffect(() => {
    getAllVideo();
  }, []);

  const getAllVideo = async () => {
    await ApiGet("video/new-videos")
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data.data);
          setAllVideos(res.data.data);
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <>
        <Header />
      </>
      <div className="flex">
        <>
          <Sidebar />
        </>
        <main className="s-layout__content">
          <div className="main-box">
            <div className="container-fluid">
              <div className="md:flex pt-24  pt-24-mobile">
                <div className="md:w-full pt-3 flex items-center pt-5-mobile">
                  <NavLink to="/" onClick={(e) => {
                      e.preventDefault();
                      history(-1)
                    }}>
                    <p>
                      <i className="fas fa-arrow-left cursor-pointer icon-font-size text-white"></i>
                    </p>
                  </NavLink>
                  <p className="pl-3 font-size-35 white-text-color font-medium tracking-normal text-center mb-0">
                    Videos
                  </p>
                </div>
              </div>
              <div className="lg:flex pt-2">
                <div className="w-full pl-3 pr-3">
                  {!allVideos?.length && (
                    <div className="dots">
                      <div className="dot"></div>
                      <div className="dot"></div>
                      <div className="dot"></div>
                    </div>
                  )}
                  <div className="lg:flex pt-4 scrolling-wrapper bottom-align-grid">
                    {allVideos && allVideos.length
                      ? allVideos?.map((record, index) => {
                          if (!record.isPrivate) {
                            return (
                              <div
                                key={index}
                                className="lg:w-1/5 pl-3 pr-3 mb-4"
                              >
                                <div className="banner-box">
                                  <video
                                    src={`${record?.video}`}
                                    controls={true}
                                    // autoPlay={false}
                                    className="object-cover"
                                  />

                                  <div className="singer-text p-5">
                                    <div
                                      className="flex items-center justify-between cursor-pointer"
                                      style={{
                                        overflow: "hidden",
                                        whiteSpace: "nowrap",
                                        textOverflow: "ellipsis",
                                        WebkitLineClamp: 3,
                                      }}
                                    >
                                      <div
                                        className="line-nowrap"
                                        // onClick={() =>
                                        //   history(`/play/${record._id}`)
                                        // }
                                      >
                                        <p className="font-size-18 white-text-color font-normal tracking-normal mb-0">
                                          {record.title}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        })
                      : ""}
                  </div>
                </div>
              </div>

              {showModalEditProfile ? (
                <>
                  <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none ">
                    <div className="relative w-auto my-6 mx-auto md:w-8/12">
                      <div className="md:w-8/12 relative modal-profile-box border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                        <div className="flex items-start justify-between p-5 rounded-t">
                          <h3 className="text-2xl font-bold text-white"> </h3>
                          <button
                            className="p-1 ml-auto bg-transparent border-0  text-white  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                            onClick={() => setShowModalEditProfile(false)}
                            OnTouchStart={() => setShowModalEditProfile(false)}

                          >
                            <p>
                              <i className="fas fa-times login-text-color relative"></i>
                            </p>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
              ) : null}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
