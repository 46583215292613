/* jshint esversion: 6 */
/* jshint esversion: 8 */
/* jshint esversion: 11 */

import React, { useEffect, useState } from "react";
import { ApiGet, ApiPost } from "../../../helpers/API/ApiData";
import Header from "../../Layout/Header";
import Sidebar from "../../Layout/Sidebar";
import "../Cart/cart.scss";
import { NavLink, useNavigate } from "react-router-dom";
import * as authUtil from "../../../utils/auth.util";
import * as userUtil from "../../../utils/user.util";
import Country from "../countries/Country";
import Userlogin from "../Login/Login";
import UserSignup from "../Login/Signup";
import Forgotpassword from "../Login/Forgotpassword";

export default function Cart(props) {
  const history = useNavigate();
  const [loading, setLoading] = useState(false);
  const [productData, setProductData] = useState();
  const [size, setSize] = useState([]);
  const [productSize, setProductSize] = useState();
  const [productCount, setProductCount] = useState(1);
  const [forgotModal, setForgotModal] = useState(false);

  const [productPrice, setProductPrice] = useState();
  const [msg, setMsg] = useState();
  const [modal, setModal] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [signupModal, setSignupModal] = useState(false);
  const [errors, setErrors] = useState([]);
  const [signupErrors, setSignupErrors] = useState([]);
  const [formData, setFormData] = useState({});
  const [signupFormData, setSignupFormData] = useState({});
  const [countryInit, setCountryInit] = useState("");
  const [passwordSeen, setPasswordSeen] = useState(false);
  const [Idc, setIdc] = useState();

  const handlePasswordSeen = () => {
    setPasswordSeen(!passwordSeen);
  };
  const validate = () => {
    let error = [];
    let formIsValid = true;

    if (!formData.email) {
      formIsValid = false;
      error.email = "Please Enter Email";
    }
    if (!formData.password) {
      formIsValid = false;
      error.password = "Please Enter Password";
    }
    setErrors(error);
    return formIsValid;
  };
  // const handleLoginCheck = (id) => {
  //   console.log(authUtil.isLoggedIn());
  //   if (authUtil.isLoggedIn() === false) {
  //     setLoginModal(true);
  //   } else {
  //     history(`/profile/${id}`);
  //   }
  // };
  const signupValidate = () => {
    let error = [];
    let formIsValid = true;

    if (!signupFormData.email) {
      formIsValid = false;
      error.email = "Please Enter Email";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(signupFormData.email)
    ) {
      formIsValid = false;
      error.email = "Please Enter proper Email";
    }

    if (!signupFormData.firstName) {
      formIsValid = false;
      error.firstName = "Please Enter FirstName";
    } else if (!/^[aA-zZ\s]+$/.test(signupFormData.firstName)) {
      formIsValid = false;
      error.firstName = "only alphabetic character are allowed";
    }
    if (!signupFormData.lastName) {
      formIsValid = false;
      error.lastName = "Please Enter LastName";
    } else if (!/^[aA-zZ\s]+$/.test(signupFormData.lastName)) {
      formIsValid = false;
      error.firstName = "only alphabetic character are allowed";
    }
    if (!signupFormData.password) {
      formIsValid = false;
      error.password = "Please Enter Password";
    }

    setSignupErrors(error);
    return formIsValid;
  };
  const handleSubmit = async (event) => {
    if ((event && event.keyCode === 13) || (event && event.type === "click")) {
      if (validate()) {
        const data = {
          email: formData.email,
          password: formData.password,
        };
        setLoading(true);
        await ApiPost("users/login", data)
          .then((res) => {
            if (res.data.message === "Successfully logged in.") {
              setLoading(false);
              authUtil.setToken(res.data.data.token);
              userUtil.setUserInfo(res.data.data);
              setLoginModal(false);
              window.location.reload();
            } else if (res.msg) {
              setLoading(false);
              setErrors({ user: "User does not exist." });
            } else {
              setLoading(false);
              setErrors({
                user: "Please check username or password",
              });
            }
          })
          .catch((err) => {
            setLoading(false);
            console.log("err", err);
          });
      }
    }
  };

  const handleSignupSubmit = async (event) => {
    if ((event && event.keyCode === 13) || (event && event.type === "click")) {
      if (signupValidate()) {
        const data = {
          firstName: signupFormData.firstName,
          lastName: signupFormData.lastName,
          email: signupFormData.email,
          password: signupFormData.password,
          contact: signupFormData.contact,
          country: countryInit,
          countryCode: Idc,
        };
        setLoading(true);
        let err = [];
        await ApiPost("users/signup", data)
          .then((res) => {
            if (res?.data?.data === "Email has been already registered.") {
              err["error"] = res.data.data;
              setSignupErrors(err);
            }
            if (res.data.message === "Record Successfully Inserted.") {
              setLoading(false);
              authUtil.setToken(res.data.data.token);
              userUtil.setUserInfo(res.data.data);
              setSignupModal(false);
              window.location.reload();
            } else {
              setLoading(false);
              setErrors({ user: res.data.data });
            }
          })
          .catch((err) => {
            setLoading(false);
            console.log("err", err);
          });
      }
    }
  };
  console.log({ signupErrors });
  const handleChange = (e) => {
    let { name, value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSignupChange = (e) => {
    let { name, value } = e.target;

    setSignupFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    const getAllProduct = async () => {
      await ApiGet(`product/${props.match.params.id}`)
        .then((res) => {
          console.log(res.data.data);
          setProductData(res.data.data);
          setSize(res.data.data.size);
        })
        .catch((err) => {
          console.log("err", err);
        });
    };

    const handleLoginCheck = () => {
      console.log(authUtil.isLoggedIn());
      if (authUtil.isLoggedIn() === true) {
        setModal(true);
      } else {
        setLoginModal(true);
      }
    };
    handleLoginCheck();
    getAllProduct();
  }, []);

  const handleProductCount = (data) => {
    if (productCount >= 1) {
      if (data === "plus") {
        setProductPrice(productData?.price * (productCount + 1));
        setProductCount(productCount + 1);
      } else if (data === "minus" && productCount >= 2) {
        setProductPrice(productData?.price * (productCount - 1));
        setProductCount(productCount - 1);
      } else {
        setProductCount(1);
      }
    } else {
      setProductCount(1);
    }
  };

  const handleAddToCart = async () => {
    const data = {
      productId: productData?._id,
      quantity: productCount,
      size: productSize,
    };
    console.log(data);

    await ApiPost("cart/add-to-cart", data)
      .then((res) => {
        console.log(res.data);
        if (res.data.data === "record already exists") {
          console.log("record already exists");
          setMsg("record already exists");
        } else if (res.data.message === "size is required!") {
          setMsg("size is required!");
        } else {
          history("/shopcart");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <>
        <Header />
      </>
      <div className="flex">
        <>
          <Sidebar />
        </>
        <main className="s-layout__content">
          {modal ? (
            <div className="main-box">
              <div className="container-fluid pt-24">
                <NavLink to="/product" onClick={(e) => {
                      e.preventDefault();
                      history(-1)
                    }} >
                  <div className="pt-5">
                    <i className="fas fa-arrow-left white-text-color font-size-22 cursor-pointer"></i>
                  </div>
                </NavLink>
              </div>
              <section>
                <div className="container-fluid">
                  <div className="lg:flex">
                    <div className="lg:w-2/3 ml-auto mr-auto">
                      <div className="cart-box flex justify-center">
                        <div className="flex items-center m-block">
                          <div className="mobile-align-grid">
                            <div className="cart-final mb-4 mobile-align-grid-space">
                              <img src={productData?.image} />
                            </div>
                            <div className="cart-final mb-4 mobile-align-grid-space">
                              <img src={productData?.image} />
                            </div>
                            <div className="cart-final mb-4 mobile-align-grid-space">
                              <img src={productData?.image} />
                            </div>
                          </div>
                          <div>
                            <div className="cart-main-img pr-8 pl-8 m-pl-0 m-pr-0">
                              <img src={productData?.image} />
                            </div>
                          </div>
                          <div className="m-pt-1">
                            <p className="font-size-25 white-text-color font-medium tracking-normal mb-0">
                              {productData?.title}
                            </p>

                            <div className="flex mt-4">
                              <p className="font-size-20 pr-4 white-text-color font-medium tracking-normal mb-0">
                                Price :-
                              </p>
                              <p className="font-size-20 font-medium tracking-normal white-text-color mb-0">
                                ${" "}
                                {productPrice
                                  ? productPrice
                                  : productData?.price}
                              </p>
                            </div>

                            {/* <div className="mt-4">
                            <select
                              id="cars"
                              className="cus-option-drp"
                              style={{ color: "#fff" }}
                              onChange={(e) => setProductColor(e.target.value)}
                            >
                              <option className="text-black" value="m" selected>
                                Color
                              </option>
                              <option className="text-black" value="saab">
                                Saab
                              </option>
                              <option className="text-black" value="vw">
                                VW
                              </option>
                              <option className="text-black" value="audi">
                                Audi
                              </option>
                            </select>
                          </div> */}
                            <div className="mt-4 flex">
                              {" "}
                              <p className="font-size-20 pr-4 white-text-color font-medium tracking-normal mb-0">
                                Size :-
                              </p>
                              <select
                                id="cars"
                                className="cus-option-drp"
                                style={{ color: "#fff" }}
                                onChange={(e) => setProductSize(e.target.value)}
                              >
                                <option
                                  className="text-black"
                                  value="white"
                                  selected
                                >
                                  Size
                                </option>

                                {size?.map((data, key) => {
                                  const abc = data.split(",");

                                  return abc.map((data) => {
                                    return (
                                      <option
                                        className="text-black"
                                        value={data}
                                        key={key}
                                      >
                                        {data}
                                      </option>
                                    );
                                  });
                                })}
                              </select>
                            </div>
                            <div className="mt-4">
                              <div className="">
                                <button className="white-text-color plus-add-btn">
                                  <div className="flex items-center justify-around">
                                    <i
                                      className="fas fa-minus"
                                      onClick={() =>
                                        handleProductCount("minus")
                                      }
                                      OnTouchStart={() =>
                                        handleProductCount("minus")
                                      }
                                    ></i>
                                    <span className="">{productCount}</span>
                                    <i
                                      className="fas fa-plus"
                                      onClick={() => handleProductCount("plus")}
                                      OnTouchStart={() => handleProductCount("plus")}
                                    ></i>
                                  </div>
                                </button>
                              </div>
                            </div>
                            <div className="mt-6">
                              <button
                                className="main-add-to-cart"
                                onClick={() => handleAddToCart()}
                                OnTouchStart={() => handleAddToCart()}
                              >
                                Add to cart
                              </button>
                              {msg && msg === "record already exists" ? (
                                <h3 className="text-red-600">
                                  product already in cart
                                </h3>
                              ) : msg === "size is required!" ? (
                                <h3 className="text-red-600">
                                  size is required
                                </h3>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="pt-16">
                <div className="container-fluid">
                  <div className="md:flex">
                    <div className="md:w-full">
                      <h1 className="cursor-pointer mb-0 font-size-40 white-text-color font-normal tracking-normal">
                        Product Description
                      </h1>
                      <p className="font-size-18 white-text-color font-normal tracking-normal white-text-color">
                        {productData?.description}
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          ) : null}
        </main>

        {forgotModal ? (
          <Forgotpassword
            setSignupModal={setSignupModal}
            setLoginModal={setLoginModal}
            setForgotModal={setForgotModal}
          />
        ) : null}

        {loginModal ? (
          <Userlogin
            setSignupModal={setSignupModal}
            setLoginModal={setLoginModal}
            setForgotModal={setForgotModal}
          />
        ) : null}

        {signupModal ? (
          <UserSignup
            setSignupModal={setSignupModal}
            setLoginModal={setLoginModal}
            setForgotModal={setForgotModal}
          />
        ) : null}
      </div>
    </>
  );
}
