/* jshint esversion: 6 */
/* jshint esversion: 8 */
/* jshint esversion: 11 */

import React, { useEffect, useRef, useState } from "react";
import "../../js/Trending/trending.scss";
import "../../../Components/styles/dashboard.scss";
import Sidebar from "../../Layout/Sidebar";
import Header from "../../Layout/Header";
import { NavLink, useNavigate } from "react-router-dom";
import Footer from "../../Layout/Footer";
import { Dropdown } from "react-bootstrap";
import { ApiGet, ApiPost } from "../../../helpers/API/ApiData";
import * as authUtil from "../../../utils/auth.util";
import * as userUtil from "../../../utils/user.util";
import { ToastContainer, toast } from "react-toastify";
import LoadingBalls from "../../../Assets/LoadingBalls";
import { useSelector, useDispatch } from "react-redux";
import { getFree } from "../../../store/reducers/AllFree/action";
import { getMusicData } from "../../../store/reducers/MusicData/action";
import axios from "axios";
import { Tooltip } from "@material-ui/core";
import Country from "../countries/Country";
import * as userUtils from "../../../utils/user.util";
import Userlogin from "../Login/Login";
import Menu from "../../../Assets/Images/dots.png";
import UserSignup from "../Login/Signup";
import Forgotpassword from "../Login/Forgotpassword";
import useOnClickOutside from "../../../helpers/outsideClick";

export default function FreeDownload() {
  const userId = userUtils.getUserInfo()?.user?.id;

  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [allPlaylist, setAllPlaylist] = useState([]);
  const [loginModal, setLoginModal] = useState(false);
  const [dId, setDownloadId] = useState();
  const [signupModal, setSignupModal] = useState(false);

  const [selectionBox, setSelectionBox] = useState(true);
  const [formBox, setFormBox] = useState(false);
  const [playlistModal, setPlaylistModal] = useState({
    open: false,
    musicId: null,
  });
  const [errors, setErrors] = useState([]);
  const [signupErrors, setSignupErrors] = useState([]);
  const [formData, setFormData] = useState({});
  const [forgotModal, setForgotModal] = useState(false);

  const [signupFormData, setSignupFormData] = useState({});
  const [countryInit, setCountryInit] = useState();
  const [Idc, setIdc] = useState();
  const [countryList, setCountryList] = useState([]);
  const [passwordSeen, setPasswordSeen] = useState(false);

  const handlePasswordSeen = () => {
    setPasswordSeen(!passwordSeen);
  };

  const history = useNavigate();
  const validate = () => {
    let error = [];
    let formIsValid = true;

    if (!formData.email) {
      formIsValid = false;
      error["email"] = "Please Enter Email";
    }
    if (!formData.password) {
      formIsValid = false;
      error["password"] = "Please Enter Password";
    }
    setErrors(error);
    return formIsValid;
  };

  const signupValidate = () => {
    let error = [];
    let formIsValid = true;

    if (!signupFormData.email) {
      formIsValid = false;
      error["email"] = "Please Enter Email";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(signupFormData.email)
    ) {
      formIsValid = false;
      error["email"] = "Please Enter proper Email";
    }

    if (!signupFormData.firstName) {
      formIsValid = false;
      error["firstName"] = "Please Enter FirstName";
    } else if (!/^[aA-zZ\s]+$/.test(signupFormData.firstName)) {
      formIsValid = false;
      error["firstName"] = "only alphabetic character are allowd";
    }
    if (!signupFormData.lastName) {
      formIsValid = false;
      error["lastName"] = "Please Enter LastName";
    } else if (!/^[aA-zZ\s]+$/.test(signupFormData.lastName)) {
      formIsValid = false;
      error["firstName"] = "only alphabetic character are allowd";
    }
    if (!signupFormData.password) {
      formIsValid = false;
      error["password"] = "Please Enter Password";
    }

    setSignupErrors(error);
    return formIsValid;
  };
  const handleSubmit = async (event) => {
    if ((event && event.keyCode === 13) || (event && event.type === "click")) {
      if (validate()) {
        const data = {
          email: formData.email,
          password: formData.password,
        };
        setLoading(true);
        await ApiPost("users/login", data)
          .then((res) => {
            if (res.data.message === "Successfully logged in.") {
              setLoading(false);
              authUtil.setToken(res.data.data.token);
              userUtil.setUserInfo(res.data.data);
              setLoginModal(false);
              window.location.reload();
            } else if (res.msg) {
              setLoading(false);
              setErrors({ user: "User does not exist." });
            } else {
              setLoading(false);
              setErrors({ user: "Please check username or password" });
            }
          })
          .catch((err) => {
            setLoading(false);
            console.log("err", err);
          });
      }
    }
  };

  const handleSignupSubmit = async (event) => {
    if ((event && event.keyCode === 13) || (event && event.type === "click")) {
      if (signupValidate()) {
        const data = {
          firstName: signupFormData.firstName,
          lastName: signupFormData.lastName,
          email: signupFormData.email,
          password: signupFormData.password,
          contact: signupFormData.contact,
          country: countryInit,
          countryCode: Idc,
        };
        setLoading(true);
        let err = [];
        await ApiPost("users/signup", data)
          .then((res) => {
            if (res?.data?.data === "Email has been already registered.") {
              err["error"] = res.data.data;
              setSignupErrors(err);
            }
            if (res.data.message === "Record Successfully Inserted.") {
              setLoading(false);
              authUtil.setToken(res.data.data.token);
              userUtil.setUserInfo(res.data.data);
              setSignupModal(false);
              window.location.reload();
            } else {
              setLoading(false);
              setErrors({ user: res.msg });
            }
          })
          .catch((err) => {
            setLoading(false);
          });
      }
    }
  };
  const handleChange = (e) => {
    let { name, value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSignupChange = (e) => {
    let { name, value } = e.target;

    setSignupFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const allFree = useSelector((state) => state.AllFree);
  const dispatch = useDispatch();
  const getCountryList = async () => {
    await axios
      .get("https://restcountries.eu/rest/v2/all")
      .then((res) => {
        setCountryList(res?.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  useEffect(() => {
    if (allFree?.length === 0 || 5) {
      getAllfree();
    }
    getAllPlaylist();
    getCountryList();
  }, []);
  const getAllPlaylist = async () => {
    await ApiGet("playlist/get-by-user")
      .then((res) => {
        if (res.status.data !== "Record list is empty") {
          setAllPlaylist(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllfree = async () => {
    await ApiGet(`music/get-all-free-music/${null}`)
      .then((res) => {
        if (res.status === 200) {
          dispatch(getFree(res.data.data));
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleAddToPlaylist = async (id) => {
    setLoading(true);
    const data = {
      playlistId: id,
      musicId: playlistModal.musicId,
    };
    await ApiPost("playlist/ad-t-playlist", data)
      .then((res) => {
        setLoading(false);
        if (res.data.message.includes("exists")) {
          toast.error("Song is already added to the playlist!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            closeOnTouchStart: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          setPlaylistModal({ open: false });
          toast.success("Song is added to the playlist!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            closeOnTouchStart: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const downloadMusic = async (id) => {
    await ApiGet(`music/download?musicId=${id}`)
      .then((res) => {})
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleSendEmail = async (id) => {
    const bodyId = {
      musicId: id,
      userId: userId,
    };
    await ApiPost(`downloadUser/downloadUsers`, bodyId)
      .then((res) => {})
      .catch((err) => {});
  };

  const handledownload = async (data) => {
    let url = data.musicUrl;
    let title = data?.musicTitle ? data?.musicTitle : data?.title;
    toast.success("Your download will begin shortly");

    await axios({
      url: url,
      method: "GET",
      responseType: "blob",
    }).then(async (response) => {
      handleSendEmail(data._id);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${title}.mp3`); //or any other extension
      document.body.appendChild(link);
      downloadUserList(data);
      await downloadMusic(data._id);
      link.click();
    });
  };

  const downloadUserList = async (data) => {
    console.log("datafff", data);
    const body = {
      musicId: data._id,
      userId: userId,
      artistId: data.artistData._id,
    };

    await ApiPost(
      `musicDownloadListForArtist/create-musicDownloadListForArtist`,
      body
    )
      .then((res) => {})
      .catch((err) => {});
  };
  console.log("window.location", window.location.href);

  const shareLink = (id) => {
    navigator.clipboard.writeText(`${window.location.href}song/${id}`);
    toast.success("Link Copied..");
  };

  const [copied, setCopied] = useState(false);

  function copy() {
    const el = document.createElement("input");
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopied(true);
  }

  const [open, setOpen] = useState({});
  const NavigatorRef2 = useRef();

  useOnClickOutside(NavigatorRef2, () => {
    setOpen({});
  });

  // play all free songs on a list/ array one by one from page to page 
  const handlePlaySong = (record, key) => {
    if (record.isPaid) {
       if (authUtil.isLoggedIn()) {
        setShowModal({
          musicId: record._id,
          open: true,
          amount: record.amount,
          musicTitle: record.title,
          artistId: record.artistData?._id,
        });
       } else {
        setLoginModal(true);
       }
    } else {
      dispatch(getMusicData({playlistData: allFree, FreeDownload,  index: key}));
    }
  };

  return (
    <div>
      <>
        <Header />
      </>
      <div className="flex">
        <>
          <Sidebar />
        </>
        <main className="s-layout__content">
          <div className="main-box mobile-Box">
            <div className="container-fluid">
              <div className="md:flex pt-24  pt-24-mobile">
                <div className="md:w-full">
                  <div className="pt-5 flex items-center pt-5-mobile">
                    <NavLink to="/" onClick={(e) => {
                      e.preventDefault();
                      history(-1)
                    }}>
                      <p>
                        <i className="fas fa-arrow-left cursor-pointer icon-font-size text-white"></i>
                      </p>
                    </NavLink>
                    <p className="pl-3 adobe-font font-size-35 white-text-color font-medium tracking-normal text-center mb-0">
                      Free Downloads
                    </p>
                  </div>
                </div>
              </div>
              {!allFree?.length && (
                <div className="dots mt-10">
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </div>
              )}
              <div className="cus-grid pt-4">
                {allFree?.length
                  ? allFree
                      ?.slice(0)
                      ?.map((record, key) => {
                        return (
                          <div className="grid-items">
                            {/* <NavLink to="/music"> */}
                            <div className="singer-box">
                              <div
                                className="singer-img"
                                onClick={() => 
                                  handlePlaySong(record, key)         // play all free songs on a list one by one from page to page 
                                         }
                                OnTouchStart={() => 
                                   handlePlaySong(record, key)}      // play all free songs on a list one by one from page to page on MObile
                              >
                                <img src={record && record.thumbnail}  alt=""/>
                              </div>
                              <div className="singer-text p-5 mini-view-p-remove">
                                <div className="flex items-center justify-between cursor-pointer">
                                  <div>
                                    <p
                                      className="font-size-18 white-text-color font-normal tracking-normal mb-0"
                                      onClick={() => 
                                        handlePlaySong(record, key)         // play all free songs on a list one by one from page to page 
                                           
                                                   }
                                      OnTouchStart={() => 
                                         handlePlaySong(record, key)}     // play all free songs on a list one by one from page to page on Mobile
                                    >
                                      {record.title}{" "}
                                    </p>
                                    <p
                                      className="font-size-18 gray-text-color font-normal tracking-normal mb-0 hover:text-white"
                                      onClick={() => {
                                        history(
                                          `/profile/${record.artistData._id}`
                                        );
                                      }}
                                      OnTouchStart={() => {
                                        history(
                                          `/profile/${record.artistData._id}`
                                        );
                                      }}
                                    >
                                      {
                                        record.artistData?.artistName
                                        // + " " +
                                        //   record.artistData.realName
                                      }{" "}
                                    </p>
                                  </div>

                                  <div className="menu_dd" ref={NavigatorRef2}>
                                    <div
                                      className="menu-img"
                                      onClick={() => {
                                        setOpen((pre) => {
                                          return {
                                            ...pre,
                                            [`newrel${record._id}`]:
                                              !pre[`newrel${record._id}`],
                                          };
                                        });
                                      }}
                                    >
                                      <img
                                        src={Menu}
                                        style={{ height: "25px" }}
                                        alt=""
                                      />
                                    </div>
                                    {open[`newrel${record._id}`] && (
                                      <div
                                        className="menu-box"
                                        // ref={NavigatorRef1}
                                      >
                                        <div
                                          className="text-white p-2 cursor-pointer"
                                          onClick={() =>
                                            authUtil.isLoggedIn()
                                              ? setPlaylistModal({
                                                  musicId: record._id,
                                                  open: true,
                                                })
                                              : setLoginModal(true)
                                          }
                                          OnTouchStart={() =>
                                            authUtil.isLoggedIn()
                                              ? setPlaylistModal({
                                                  musicId: record._id,
                                                  open: true,
                                                })
                                              : setLoginModal(true)
                                          }
                                        >
                                          Add to playlist
                                        </div>

                                        <div
                                          className="text-white p-2 cursor-pointer"
                                          onClick={() =>
                                            dispatch(
                                              getMusicData(record, key)
                                            )
                                          }
                                          OnTouchStart={() =>
                                            dispatch(
                                              getMusicData(record, key)
                                            )
                                          }
                                        >
                                          Play song now
                                        </div>
                                        <div
                                          className="text-white p-2 cursor-pointer"
                                          onClick={() =>
                                            history(`/song/${record._id}`)
                                          }
                                          OnTouchStart={() =>
                                            history(`/song/${record._id}`)
                                          }
                                        >
                                          Details
                                        </div>
                                        <div
                                          className="text-white p-2 cursor-pointer"
                                          onClick={() => {
                                            setDownloadId(record._id);
                                            authUtil.isLoggedIn()
                                              ? handledownload(record, key)
                                              : setLoginModal(true);
                                          }}
                                          OnTouchStart={() => {
                                            setDownloadId(record._id);
                                            authUtil.isLoggedIn()
                                              ? handledownload(record, key)
                                              : setLoginModal(true);
                                          }}
                                        >
                                          Download
                                        </div>
                                        <div
                                          className="text-white p-2 cursor-pointer"
                                          onClick={() => {
                                            shareLink(record._id);
                                          }}
                                          OnTouchStart={() => {
                                            shareLink(record._id);
                                          }}
                                        >
                                          <button onClick={copy} OnTouchStart={copy}>
                                            {!copied
                                              ? "Copy link"
                                              : "Copy link"}
                                          </button>
                                        </div>
                                      </div>
                                    )}
                                  </div>

                                  
                                </div>
                              </div>
                            </div>
                            {/* </NavLink> */}
                          </div>
                        );
                      })
                  : ""}
              </div>
              {/* //* Playlist modal */}
              {playlistModal.open ? (
                <>
                  <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="relative w-auto my-6 mx-auto max-w-3xl">
                      <div className="playlist border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="justify-content-center p-5 relative  border-solid border-white-200 rounded-t">
                          <h3 className="text-2xl text-center font-bold text-white">
                            Select Playlist
                          </h3>

                          <button
                            className="p-1 ml-auto bg-transparent border-0 text-white  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                            onClick={() =>
                              setPlaylistModal({
                                ...playlistModal,
                                open: false,
                              })
                            }
                            OnTouchStart={() =>
                              setPlaylistModal({
                                ...playlistModal,
                                open: false,
                              })
                            }
                          >
                            <p className="button-center-modal">
                              <i className="fas fa-times"></i>
                            </p>
                          </button>
                        </div>

                        <div className="relative p-6 flex-auto">
                          <p className="my-4 text-white text-lg leading-relaxed">
                            <div
                              className="flex items-center flex-row flex-wrap pt-4"
                              style={{ height: "450px", overflowY: "overlay" }}
                            >
                              {allPlaylist && allPlaylist.length ? (
                                allPlaylist.map((record, index) => {
                                  return (
                                    <div
                                      className="pl-3 pr-3 cursor-pointer "
                                      key={index}
                                      onClick={() => {
                                        handleAddToPlaylist(record._id);
                                      }}
                                      OnTouchStart={() => {
                                        handleAddToPlaylist(record._id);
                                      }}
                                    >
                                      <div className="singer-box">
                                        <div className="singer-img">
                                          <img
                                            src={
                                              record &&
                                              record.Songs &&
                                              record.Songs.length &&
                                              record.Songs[0].musicImage
                                            }
                                            style={{
                                              height: "150px",
                                              width: "335px",
                                            }}
                                            alt=""
                                          />
                                        </div>
                                        <div className="singer-text p-4">
                                          <div className="flex items-center justify-between">
                                            <div>
                                              <p className="font-size-14 white-text-color font-normal tracking-normal mb-0">
                                                {record.playlist_name}
                                              </p>
                                            </div>
                                            <div>
                                              {record?.Songs?.map((rec) => {
                                                if (
                                                  rec._id ===
                                                  playlistModal.musicId
                                                ) {
                                                  return (
                                                    <>
                                                      <p className="cursor-pointer font-size-14 login-text-color">
                                                        added
                                                        <i className="pl-1 fas fa-check cursor-pointer font-size-14 login-text-color"></i>
                                                      </p>
                                                    </>
                                                  );
                                                }
                                              })}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })
                              ) : (
                                <div className="dots">
                                  <div className="dot"></div>
                                  <div className="dot"></div>
                                  <div className="dot"></div>
                                </div>
                              )}
                            </div>
                          </p>
                        </div>
                        {/*footer*/}
                        <div
                          className="flex items-center justify-end p-4 border-solid border-white rounded-b"
                        >
                          <button
                            className="text-red-500 border-solid border-white font-bold uppercase px-6 py-2 text-sm  mr-1 mb-1 ease-linear transition-all duration-150"
                            type="button"
                            onClick={() =>
                              setPlaylistModal({
                                ...playlistModal,
                                open: false,
                              })
                            }
                            OnTouchStart={() =>
                              setPlaylistModal({
                                ...playlistModal,
                                open: false,
                              })
                            }
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
              ) : null}

              {forgotModal ? (
                <Forgotpassword
                  setSignupModal={setSignupModal}
                  setLoginModal={setLoginModal}
                  setForgotModal={setForgotModal}
                />
              ) : null}

              {loginModal ? (
                <Userlogin
                  setSignupModal={setSignupModal}
                  setLoginModal={setLoginModal}
                  setForgotModal={setForgotModal}
                />
              ) : null}

              {signupModal ? (
                <UserSignup
                  setSignupModal={setSignupModal}
                  setLoginModal={setLoginModal}
                  setForgotModal={setForgotModal}
                />
              ) : null}
            </div>
          </div>
          {loading && <LoadingBalls />}
        </main>
      </div>
    </div>
  );
}
