// src/Login.js
import React from 'react';
import { ApiPostNoAuth, ApiGet } from '../helpers/API/ApiData';
import * as authUtil from "@/utils/auth.util";
import * as userUtil from "@/utils/user.util";
import GoogleAuth from './GoogleAuth';
import { toast } from 'react-toastify';

const GoogleLoginCom = () => {
  const handleLoginSuccess = async (credentialResponse) => {
    try {
      const response = await ApiPostNoAuth("auth/google-signin/", { token: credentialResponse.credential });
      if (response.data && response.data.message === "Google sign-in successful") {
        authUtil.setToken(credentialResponse.credential);
        const userInfoResponse = await ApiGet("auth/google-user");
        userUtil.setUserInfo(userInfoResponse.data);
        window.location.reload();
      }
    } catch (error) {
      toast.error('🔴 Google Sign-Up went wrong!');
    }
  };

  const handleLoginError = () => {
    toast.error('🔴 Google Sign-Up went wrong!');
  };

  return (
    <GoogleAuth handleSuccess={handleLoginSuccess} handleError={handleLoginError}></GoogleAuth>
  );
};

export default GoogleLoginCom;
