import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { ButtonGroup, Button, ButtonToolbar } from "react-bootstrap";
import Video1 from "../../Assets/Images/A1.png";
import Video from "../../Assets/Images/A.png";
import RedUpload from "../../Assets/Images/redupload.svg";
import WhiteUpload from "../../Assets/Images/whiteupload.svg";
export default function Sidebar(props) {
  //console.log(props.data)
  const [SideBarClass, setSideBarClass] = useState("s-sidebar__nav");
  const route = window.location.pathname;

  useEffect(() => {
    if (
      // route === "/shop" ||
      // route === "/create-playlist" ||
      // route === "/shopcart" ||
      // route === "/product" ||
      // route === "/myorders" ||
      // route === "/news" ||
      // route === "/service" ||
      // route === "/cart" ||
      /^\/cart\/.*/.test(route)
    ) {
      setSideBarClass("full-sidebar-height");
    } else {
      setSideBarClass("half-sidebar-height");
    }
  }, [route]);
  return (
    <div>
      <div className="s-layout">
        <div
          className={`s-layout__sidebar s-sidebar__nav sidebar-top-align-view  ${SideBarClass}`}
        >
          <a className="s-sidebar__trigger mobile-view-show-icon" href="#0">
            <i className="fa fa-bars"></i>
          </a>
          {/* calc(100vh - 0px) !import; */}
          <nav className="">
            {/* <div className="company-logo flex items-center justify-center pt-3">
              <img src={require("../../Assets/Images/7cmg-logo.png").default} />
            </div>
            <div className="mini-sidebar-show pt-3 flex justify-center">
              <img src={require("../../Assets/Images/mini-logo.png").default} />
            </div> */}
            <ul className={`menu-nav ${props.ulClasses}`}>
              <li className="sidebar-left-right-align pt-1">
                <NavLink
                  className={`s-sidebar__nav-link ${
                    route === "/" ? "s-sidebar__nav-link-active" : null
                  }`}
                  to="/"
                >
                  {/* <i className="fa fa-home"></i> */}
                  <div className="sidebar-img flex justify-center items-center ">
                    {route === "/" ? (
                      <img
                        src={require("../../Assets/Images/active1.svg").default}
                        alt=""
                      />
                    ) : (
                      <img
                        src={require("../../Assets/Images/home.svg").default}
                        alt=""
                      />
                    )}
                  </div>
                  <em>Home</em>
                </NavLink>
              </li>

              <li className="sidebar-left-right-align">
                <NavLink
                  className={`s-sidebar__nav-link ${
                    route === "/playlist" ? "s-sidebar__nav-link-active" : null
                  }`}
                  to="/playlist"
                >
                  <div className="sidebar-img flex items-center justify-center">
                    {/* <img
                      src={
                        require("../../Assets/Images/playlist-sidebar.svg")
                          .default
                      }
                    /> */}
                    <div className="sidebar-img flex justify-center items-center">
                      {route === "/playlist" ? (
                        <img
                          src={
                            require("../../Assets/Images/playlist-sidebar.svg")
                              .default
                          } alt=""
                        />
                      ) : (
                        <img
                          src={
                            require("../../Assets/Images/playlist-s.svg")
                              .default
                          } alt=""
                        />
                      )}
                    </div>
                  </div>
                  <em>Playlist</em>
                </NavLink>
              </li>

              <li className="sidebar-left-right-align">
                <NavLink
                  className={`s-sidebar__nav-link ${
                    route === "/shop" ? "s-sidebar__nav-link-active" : null
                  }`}
                  to="/shop"
                >
                  <div className="sidebar-img flex justify-center items-center">
                    {route === "/shop" ? (
                      <img
                        src={require("../../Assets/Images/Path 72.svg").default}
                        alt=""
                      />
                    ) : (
                      <img
                        src={require("../../Assets/Images/t-shirt.svg").default}
                        alt=""
                      />
                    )}
                  </div>

                  <em>Shop</em>
                </NavLink>
              </li>

              <li className="sidebar-left-right-align">
                <NavLink
                  className={`s-sidebar__nav-link ${
                    route === "/shopcart" ? "s-sidebar__nav-link-active" : null
                  }`}
                  to="/shopcart"
                >
                  <div className="sidebar-img flex justify-center items-center">
                    {route === "/shopcart" ? (
                      <img
                        src={
                          require("../../Assets/Images/shopping-cart1.svg")
                            .default
                        }
                        alt=""
                      />
                    ) : (
                      <img
                        src={
                          require("../../Assets/Images/shopping-cart.svg")
                            .default
                        } alt=""
                      />
                    )}
                  </div>
                  <em>Cart</em>
                </NavLink>
              </li>

              <li className="sidebar-left-right-align">
                <NavLink
                  className={`s-sidebar__nav-link ${
                    route === "/news" ? "s-sidebar__nav-link-active" : null
                  }`}
                  to="/news"
                >
                  <div className="sidebar-img flex justify-center items-center">
                    {route === "/news" ? (
                      <img
                        src={
                          require("../../Assets/Images/newspink.svg").default
                        } alt=""
                      />
                    ) : (
                      <img
                        src={
                          require("../../Assets/Images/newswhite.svg").default
                        } alt=""
                      />
                    )}
                  </div>
                  <em>News</em>
                </NavLink>
              </li>

              <li className="sidebar-left-right-align">
                <NavLink
                  className={`s-sidebar__nav-link ${
                    route === "/video" ? "s-sidebar__nav-link-active" : null
                  }`}
                  to="/video"
                >
                  <div className="sidebar-img flex justify-center items-center">
                    {route === "/video" ? (
                      <img src={Video1} alt=""/>
                    ) : (
                      <img src={Video} alt=""/>
                    )}
                  </div>
                  <em>Video</em>
                </NavLink>
              </li>

              <li className="sidebar-left-right-align">
                <NavLink
                  className={`s-sidebar__nav-link ${
                    route === "/service" ? "s-sidebar__nav-link-active" : null
                  }`}
                  to="/service"
                >
                  <div className="sidebar-img flex justify-center items-center">
                    {route === "/service" ? (
                      <img
                        src={require("../../Assets/Images/s-1.svg").default}
                        alt=""
                      />
                    ) : (
                      <img
                        src={
                          require("../../Assets/Images/information.svg").default
                        }alt=""
                      />
                    )}
                  </div>
                  <em>Learning Hub</em>
                </NavLink>
              </li>

              <li className="sidebar-left-right-align">
                <NavLink
                  className={`s-sidebar__nav-link ${
                    route === "/create-playlist"
                      ? "s-sidebar__nav-link-active"
                      : null
                  }`}
                  to="/create-playlist"
                >
                  <div className="sidebar-img flex justify-center items-center">
                    {route === "/create-playlist" ? (
                      <img
                        src={
                          require("../../Assets/Images/sidebar-active3.svg")
                            .default
                        } alt=""
                      />
                    ) : (
                      <img
                        src={
                          require("../../Assets/Images/Group 253.svg").default
                        } alt=""
                      />
                    )}
                  </div>
                  <em>Create Playlist</em>
                </NavLink>
              </li>

              <li className="sidebar-left-right-align">
                <a
                  className={`s-sidebar__nav-link ${
                    route === "/create-playlist"
                      ? "s-sidebar__nav-link-active"
                      : null
                  }`}
                  href={"//" + "admin.7thcentury.co.uk/"}
                  target="_blank" rel="noreferrer"
                >
                  <div className="sidebar-img flex justify-center items-center">
                    <img src={WhiteUpload} alt=""/>
                  </div>
                  <em>Upload Music</em>
                </a>
              </li>

              <li className="sidebar-left-right-align">
                <a
                  className={`s-sidebar__nav-link ${
                    route === "/affiliates"
                      ? "s-sidebar__nav-link-active"
                      : null
                  }`}
                  href={"//" + "admin.7thcentury.co.uk/auth/registration"}
                  target="_blank" rel="noreferrer"
                >
                  <div className="sidebar-img flex justify-center items-center">
                    <img
                      src={require("../../Assets/Images/user_icon.svg").default}
                      alt=""
                    />
                  </div>
                  <em>Affiliates</em>
                </a>
              </li>

              <div className=" mini-sidebar-text-none">
                <NavLink to="/termsofuse" >
                  <li className="white-text-color text-center">Terms of Use</li>
                </NavLink>
                <NavLink to="/onboarding-policy">
                  <li className="white-text-color text-center">
                    Onboarding Policy
                  </li>
                </NavLink>
                <div className="flex items-center justify-center whitespace-nowrap ">
                  <div className="pr-2">
                    <NavLink to="/privacypolicy">
                      <li className="white-text-color text-center">
                        Privacy Policy
                      </li>
                    </NavLink>
                  </div>
                  <div className="pl-2">
                    <NavLink to="/questions">
                      <li className="white-text-color text-center">F.A.Q's</li>
                    </NavLink>
                  </div>
                </div>
                <div className=" ">
                  <li className="white-text-color text-center pt-1">
                    <h2 className="align-text-bottom ">
                      &copy; 2023 7th Century Music
                    </h2>
                  </li>
                </div>
              </div>
              <div className="mobile-view-social-media-show">
                <div className="flex items-center justify-around whitespace-nowrap padding-right-remove-header pt-1">
                  <ButtonToolbar aria-label="Toolbar with button groups">
                    <ButtonGroup aria-label="group">
                      <Button className="pr-3 pl-2 text-white">
                        <a
                          className="btn btn-primary"
                          href="https://www.facebook.com/7thCenturyMedia/"
                          target="self"
                          role="button"
                        >
                          <i className="fab fa-facebook-f" />
                        </a>
                      </Button>
                    </ButtonGroup>
                  </ButtonToolbar>

                  <ButtonToolbar aria-label="Toolbar with button groups">
                    <ButtonGroup aria-label="group">
                      <Button className="pr-3 pl-2 text-white">
                        <a
                          className="btn btn-primary"
                          href="https://www.instagram.com/7th.century.music/"
                          target="self"
                          role="button"
                        >
                          <i className="fab fa-instagram" />
                        </a>
                      </Button>
                    </ButtonGroup>
                  </ButtonToolbar>

                  <ButtonToolbar aria-label="Toolbar with button groups">
                    <ButtonGroup aria-label="group">
                      <Button className="pr-3 pl-2 text-white">
                        <a
                          className="btn btn-primary"
                          href="https://www.youtube.com/channel/UCZSMJE5tMI5QTyfWEOj1d2Q"
                          target="self"
                          role="button"
                        >
                          <i className="fab fa-youtube" />
                        </a>
                      </Button>
                    </ButtonGroup>
                  </ButtonToolbar>

                  <ButtonToolbar aria-label="Toolbar with button groups">
                    <ButtonGroup aria-label="group">
                      <Button className="pr-2 pl-2 text-white">
                        <a
                          className="btn btn-primary"
                          href="https://twitter.com/7thCenturyMedia"
                          target="self"
                          role="button"
                        >
                          <i className="fab fa-twitter" />
                        </a>
                      </Button>
                    </ButtonGroup>
                  </ButtonToolbar>
                </div>
              </div>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
}
