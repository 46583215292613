import React from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { toast } from 'react-toastify';

const GoogleAuth = ({
  handleSuccess = () => {},
  handleError = () => {},
}) => {
  return (
    <div className="flex justify-center mt-5">
      <div className="mr-5">
        <GoogleLogin
          onSuccess={handleSuccess}
          onError={handleError}
        />
      </div>
      <div>
        <button
          className="px-4 py-2 white-text-color font-normal tracking-normal cursor-pointer"
          type="button"
          style={{ backgroundColor: "black", color: "white", borderRadius: "4px", width: "192px" }}
          onClick={() => toast.warn("iCloud Login will be available soon.")}
        >
          <i className="fab fa-apple mr-2"></i> with iCloud
        </button>
      </div>
    </div>

  );
};

export default GoogleAuth;
