/* jshint esversion: 6 */
/* jshint esversion: 8 */
/* jshint esversion: 11 */
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import LoadingBalls from "../../../Assets/LoadingBalls";
import { ApiGet, ApiPost } from "../../../helpers/API/ApiData";
import * as authUtil from "../../../utils/auth.util";
import { isLoggedIn } from "../../../utils/auth.util";
import * as userUtil from "../../../utils/user.util";
import "../../js/Playlist/playlist.scss";
import Header from "../../Layout/Header";
import Sidebar from "../../Layout/Sidebar";
import { useDispatch } from "react-redux";
import { getMyPlaylist } from "../../../store/reducers/AllMyPlaylist/action";
import { getPlaylist } from "../../../store/reducers/AllPlaylist/action";
import { Tooltip } from "@material-ui/core";
import axios from "axios";
import { getMusicData } from "../../../store/reducers/MusicData/action";
import { ToastContainer, toast } from "react-toastify";
import * as userUtils from "../../../utils/user.util";
import Forgotpassword from "../Login/Forgotpassword";
import Userlogin from "../Login/Login";
import UserSignup from "../Login/Signup";

export default function Song() {
  let number = Math.floor(Math.random() * 10);
  const userId = userUtils.getUserInfo()?.user?.id;
  const route = window.location.pathname;
  var musicId = route.split("/").slice(-1)[0];
  const [signupModal, setSignupModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [musicDetails, setMusicdetails] = useState([]);
  const [highlight, setHighlight] = useState();
  const [userPlaylist, setUserPlaylist] = useState([]);
  const [forgotModal, setForgotModal] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [dId, setDownloadId] = useState();
  const [playlistModal, setPlaylistModal] = useState({
    open: false,
    musicId: null,
  });

  const history = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    getMusicDetails();
    getAllPlayMylist();
    getByUserPlaylist();
  }, []);

  const getMusicDetails = async () => {
    setLoading(true);
    const data = {
      id: musicId,
    };
    await ApiPost(`music/get-music-by-id`, data)
      .then((res) => {
        setLoading(false);
        setMusicdetails(res.data.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log("err", err);
      });
  };

  const getByUserPlaylist = async () => {
    await ApiGet("playlist/get-by-user")
      .then((res) => {
        if (res.status.data !== "Record list is empty") {
          setUserPlaylist(res.data.data);
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllPlayMylist = async () => {
    let limit = 5;
    await ApiGet(`playlist/get-all-playlist/${limit}`)
      .then((res) => {
        if (res.status.data !== "Record list is empty") {
          dispatch(getMyPlaylist(res.data.data));
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAddToPlaylist = async (id) => {
    const data = {
      playlistId: id,
      musicId: playlistModal.musicId,
    };
    setLoading(true);
    await ApiPost("playlist/add-to-playlist", data)
      .then((res) => {
        setLoading(false);
        if (res.data.message.includes("exists")) {
          toast.error("Song is already added to the playlist!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            closeOnTouchStart: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          setPlaylistModal({ open: false });
          toast.success("Song is added to the playlist!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            closeOnTouchStart: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("err", err);
      });
  };

  const downloadMusic = async (id) => {
    console.log("Downloading");
    await ApiGet(`music/download?musicId=${id}`)
      .then((res) => {
        console.log("Downloads", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleSendEmail = async (id) => {
    const bodyId = {
      musicId: id,
      userId: userId,
    };
    await ApiPost(`downloadUser/downloadUsers`, bodyId)
      .then((res) => {
        console.log("Downloads", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const [show, setShow] = useState(false);  // this is the definition of onmouseEnter and onMouseLeave
  const [showModal, setShowModal] = useState(false);


  const handledownload = async (data) => {
    console.log("object");
    toast.success("Your download will begin shortly");
    let url = data.musicUrl;
    let title = data?.musicTitle ? data?.musicTitle : data?.title;
    setLoading(true);
    console.log("object1");
    await axios({
      url: url,
      method: "GET",
      responseType: "blob",
      headers: {
        "Content-Type": "video/mpeg",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then(async (response) => {
        console.log("object2", response);
        handleSendEmail(data._id);
        setLoading(false);
        console.log("Response", response);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${title}.mp3`); //or any other extension
        document.body.appendChild(link);
        link.click();
        downloadUserList(data);
        await downloadMusic(data._id);
        console.log("object3", response);
      })
      .catch((err) => {
        console.log("object4", err);
        setLoading(false);
        console.log("errDownload", err);
      });
  };

  const downloadUserList = async (data) => {
    const body = {
      musicId: data._id,
      userId: userId,
      artistId: data.artistData._id,
    };

    await ApiPost(
      `musicDownloadListForArtist/create-musicDownloadListForArtist`,
      body
    )
      .then((res) => {
        console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxx", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const shareLink = (id) => {
    navigator.clipboard.writeText(`${window.location.href}song/${id}`);
    toast.success("Link Copied..");
  };

  const [copied, setCopied] = useState(false);

  function copy() {
    const el = document.createElement("input");
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopied(true);
  }

  const handleMusicClick = (index, record) => {
    if (record.isPaid) {
      isLoggedIn()
        ? userUtil?.getMusicData()?.includes(record._id) &&
          dispatch(
            getMusicData({
              musicUrl: record.musicUrl,
              title: record.title,
              artistData: {
                artistName: record.artistData?.artistName,
                realName: record.artistData?.realName,
              },
            })
          )
        : toast.error("Purchase the song first!!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            closeOnTouchStart: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
    } else {
      dispatch(
        getMusicData({
          musicUrl: record.musicUrl,   
          title: record.title,
          artistData: {
            artistName: record.artistData.artistName,
            realName: record.artistData.realName,
          },
        })
      );
    }
  };

  
  function convertStoMs(seconds) {
    let minutes = Math.floor(seconds / 60);
    let extraSeconds = seconds % 60;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    extraSeconds = extraSeconds < 10 ? "0" + extraSeconds : extraSeconds;
    let fff = minutes + " : " + parseInt(extraSeconds);
    return fff;
  }

  return (
    <div>
      
        <Header />
      
      <div className="flex">
        
          <Sidebar />
        
        <main className="s-layout__content">
          <div className="main-box pt-12 mt-12">
            <div className="container-fluid">
            <div className="md:w-full pt-5 flex items-center ">
             <NavLink 
              to="/" onClick={(e) => {
                      e.preventDefault();
                      history(-1)
                    }}>
                    <p>
                      <i className="fas fa-arrow-left cursor-pointer icon-font-size text-white"></i>
                    </p>
              </NavLink>
            </div>
              <div className="lg:flex pt-5">
            
                <div className=" grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 w-full pl-3 pr-3">
                  <div
                    className="play-child-image-border-radius play-main-banner flex items-end"
                    style={{
                      backgroundImage: `url(${musicDetails[0]?.thumbnail})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  >
                    <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 p-1 m-1">
                    <img
                      src={musicDetails[0]?.thumbnail}
                      className="play-child-image-border-radius rounded-2xl p-4 m-4 "
                      style={{ width: "250px", height: "250px"}}
                      alt=""
                    /> 
                      <h3 className="song-text font-size-39">
                        {musicDetails[0]?.title}
                      </h3>
                      <h3
                        className="song-text cursor-pointer"
                        onClick={() => {
                          history(
                            `/profile/${musicDetails[0]?.artistData?._id}`
                          );
                        }}
                        OnTouchStart={() => {
                          history(
                            `/profile/${musicDetails[0]?.artistData?._id}`
                          );
                        }}
                      >
                        Music By: {musicDetails[0]?.artistData?.artistName}{" "}
                        {/* {musicDetails[0]?.artistData?.realName} */}
                      </h3>
                      <h3 className="song-text">
                        Description: {musicDetails[0]?.description}
                      </h3>
                      {/* <h3 className="song-text">
                        views: {musicDetails[0]?.views}
                      </h3> */}
                    </div>
                  </div>
                  <div className="pt-4"></div>
                <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-1">
                  <div className="play-table1">
                    <table width="100%">
                      <thead className="text-white">
                        <tr>
                          <td className="pl-3 px-2">Title</td>
                          <td className="pl-3 px-2">Artist</td>
                        </tr>
                      </thead>
                      <tbody className="play-table-details1">
                        {musicDetails?.length ? (
                          musicDetails?.map((record, index) => {
                            return (
                              <tr
                                key={index}
                                className={`${
                                  highlight
                                    ? highlight === index && "table-heighlite"
                                    : index === 0 && "table-heighlite"
                                }`}
                              >
                                <td
                                  className="text-left cursor-pointer"
                                  onClick={() =>
                                    handleMusicClick(index, record)
                                  }
                                  OnTouchStart={() =>
                                    handleMusicClick(index, record)
                                  }
                                >
                                  <span className="font-size-16 white-text-color tracking-normal font-medium cursor-pointer">
                                    {record?.title}
                                  </span>
                                </td>
                              
                                <td
                                  className="text-left cursor-pointer"
                                  onClick={() =>
                                    handleMusicClick(index)
                                  }
                                  OnTouchStart={() =>
                                    handleMusicClick(index)
                                  }
                                >
                                <div style= {{display: "flex", flexDirection: "row",  alignItems: "center"}}> 
                                  <span
                                    className={`font-size-16 cursor-pointer tracking-normal font-medium   ${
                                      highlight
                                        ? highlight === index &&
                                          "white-text-color"
                                        : index === 0 && "white-text-color"
                                    }`}
                                    onClick={() => {
                                      history(
                                        `/profile/${record.artistData._id}`
                                      );
                                    }}
                                    OnTouchStart={() => {
                                      history(
                                        `/profile/${record.artistData._id}`
                                      );
                                    }}
                                  >
                                    {record?.artistData.artistName}{" "}
                                  </span>
                                 <div className="My_Dropdown "> 
                                 {/* This logic of My_Dropdown or any sho= {show}, onMouseEnter and onMouseLeave was made to trig the dropdown-menu 
                                 as it's SCC was not well define in page playlist.Scss ref line 16... comment */}
                                    <Dropdown show={show} 
                                    onMouseEnter={() => setShow(true)}
                                      onMouseLeave={() => setShow(false)}>
                                      <Dropdown.Toggle
                                        variant="success"
                                        id="dropdown-basic"
                                        className="text-white"
                                      >
                                        <Tooltip title="More" arrow>
                                          <i className="fas fa-ellipsis-v cursor-pointer font-size-10 text-white p-3"></i>
                                        </Tooltip>
                                      </Dropdown.Toggle>
                                       { show &&(<Dropdown.Menu>
                                        <div
                                          className="flex flex-col w-32 max-w-full box-shadow-cus border-color"
                                          style={{
                                            backgroundColor: "#090C10",
                                          }}
                                        >
                                          <div
                                            className="text-white p-2 cursor-pointer"
                                            onClick={() =>
                                              authUtil.isLoggedIn()
                                                ? setPlaylistModal({
                                                    musicId: record._id,
                                                    open: true,
                                                  })
                                                : setLoginModal(true)
                                            }
                                            OnTouchStart={() =>
                                              authUtil.isLoggedIn()
                                                ? setPlaylistModal({
                                                    musicId: record._id,
                                                    open: true,
                                                  })
                                                : setLoginModal(true)
                                            }
                                          >
                                            Add to playlist
                                          </div>
                                          <div
                                            className="text-white p-2 cursor-pointer"
                                            onClick={() =>
                                              history(`/song/${record._id}`)
                                            }
                                            OnTouchStart={() =>
                                              history(`/song/${record._id}`)
                                            }
                                          >
                                            Details
                                          </div>
                                          {!record.isPaid && (
                                            <div
                                              className="text-white p-2 cursor-pointer"
                                              onClick={() => {
                                                setDownloadId(record._id);
                                                console.log(
                                                  "Download Onclick",
                                                  "Download OnTouchStart",
                                                  record._id
                                                );
                                                authUtil.isLoggedIn()
                                                  ? handledownload(record)
                                                  : setLoginModal(true);
                                              }}
                                              OnTouchStart={() => {
                                                setDownloadId(record._id);
                                                console.log(
                                                  "Download Onclick",
                                                  "Download OnTouchStart",
                                                  record._id
                                                );
                                                authUtil.isLoggedIn()
                                                  ? handledownload(record)
                                                  : setLoginModal(true);
                                              }}
                                            >
                                              Download
                                            </div>
                                          )}
                                          <div
                                            className="text-white p-2 cursor-pointer"
                                            onClick={() => {
                                              shareLink(record._id);
                                            }}
                                            OnTouchStart={() => {
                                              shareLink(record._id);
                                            }}
                                          >
                                            <button onClick={copy} OnTouchStart={copy}>
                                              {!copied
                                                ? "Copy link"
                                                : "Copy link"}
                                            </button>
                                          </div>
                                        </div>
                                      </Dropdown.Menu>)}
                                      
                                    </Dropdown>
                                  </div>
                                </div>
                                </td>                                                                                         
                              </tr>
                            );
                          })
                        ) : (
                          <div className="dots mt-10">
                            <div className="dot"></div>
                            <div className="dot"></div>
                            <div className="dot"></div>
                          </div>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>  
            </div>
          </div>
        </div>
          {forgotModal ? (
            <Forgotpassword
              setSignupModal={setSignupModal}
              setLoginModal={setLoginModal}
              setForgotModal={setForgotModal}
            />
          ) : null}
          {loginModal ? (
            <Userlogin
              setSignupModal={setSignupModal}
              setLoginModal={setLoginModal}
              setForgotModal={setForgotModal}
            />
          ) : null}
          {signupModal ? (
            <UserSignup
              setSignupModal={setSignupModal}
              setLoginModal={setLoginModal}
              setForgotModal={setForgotModal}
            />
          ) : null}
          {playlistModal.open ? (
            <>
              <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                  {/*content*/}
                  <div className="playlist border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    {/*header*/}
                    <div
                      className="justify-content-center p-5 relative  border-solid border-white-200 rounded-t"
                      
                    >
                      <h3 className="text-2xl text-center font-bold text-white">
                        Select Playlist
                      </h3>

                      <button
                        className="p-1 ml-auto bg-transparent border-0 text-white  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                        onClick={() =>
                          setPlaylistModal({
                            ...playlistModal,
                            open: false,
                          })
                        }
                        OnTouchStart={() =>
                          setPlaylistModal({
                            ...playlistModal,
                            open: false,
                          })
                        }
                      >
                        <p className="button-center-modal">
                          <i className="fas fa-times"></i>
                        </p>
                      </button>
                    </div>
                    {/*body*/}
                    <div
                      className="relative p-6 flex-auto"
                      // style={{
                      //   backgroundColor: "#090C10",
                      // }}
                    >
                      <p className="my-4 text-white text-lg leading-relaxed">
                        <div
                          className="flex items-center flex-row flex-wrap pt-4"
                          style={{
                            height: "450px",
                            overflowY: "overlay",
                          }}
                        >
                          {userPlaylist && userPlaylist.length ? (
                            userPlaylist.map((record, index) => {
                              return (
                                <div
                                  className="pl-3 pr-3 cursor-pointer "
                                  key={index}
                                  onClick={() => {
                                    handleAddToPlaylist(record._id);
                                  }}
                                  OnTouchStart={() => {
                                    handleAddToPlaylist(record._id);
                                  }}
                                >
                                  <div className="card-holder">
                                    <div className="singer-box">
                                      <div className="singer-img">
                                        <img
                                          src={
                                            (record &&
                                              record.Songs &&
                                              record.Songs.length &&
                                              record.Songs[0].musicImage) ||
                                            `https://source.unsplash.com/1600x900/?${record.playlist_name}`
                                          }
                                          style={{
                                            height: "150px",
                                            width: "335px",
                                          }}
                                          alt=""
                                        />
                                      </div>
                                      <div className="singer-text p-4">
                                        <div className="flex items-center justify-between">
                                          <div>
                                            <p className="font-size-14 white-text-color font-normal tracking-normal mb-0">
                                              {record.playlist_name}
                                            </p>
                                          </div>
                                          <div>
                                            {record?.Songs?.map((rec) => {
                                              if (
                                                rec._id ===
                                                playlistModal.musicId
                                              ) {
                                                return (
                                                  <>
                                                    <p className="cursor-pointer font-size-14 login-text-color">
                                                      added
                                                      <i className="pl-1 fas fa-check cursor-pointer font-size-14 login-text-color"></i>
                                                    </p>
                                                  </>
                                                );
                                              }
                                            })}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <div className="justify-content-center widthFull">
                              <h1 className="text-white text-2xl mt-10">
                                Playlist Is Empty.
                              </h1>
                            </div>
                          )}
                        </div>
                      </p>
                    </div>
                    {/*footer*/}
                    <div
                      className="flex items-center justify-end p-4  border-solid border-white  "
                      // style={{
                      //   backgroundColor: "#090C10",
                      // }}
                    >
                      <button
                        className="closebtn text-white border-solid border border-white font-bold uppercase px-6 py-2 text-sm  mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() =>
                          setPlaylistModal({
                            ...playlistModal,
                            open: false,
                          })
                        }
                        OnTouchStart={() =>
                          setPlaylistModal({
                            ...playlistModal,
                            open: false,
                          })
                        }
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
          ) : null}
          {loading && <LoadingBalls />}
        </main>
      </div>
    </div>
  );
}
