/* jshint esversion: 6 */
/* jshint esversion: 8 */
/* jshint esversion: 11 */
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { ApiDelete, ApiGet, ApiPut } from "../../helpers/API/ApiData";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import Sidebar from "../Layout/Sidebar";
import * as authUtil from "../../utils/auth.util";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Payments from "../js/Payments/Payments";

const stripePromise = loadStripe(`${process.env.REACT_APP_PK_LIVE}`);

export default function ShopCart() {
  const history = useNavigate();

  const [cartProducts, setCartProducts] = useState();
  const [cartLength, setCartLength] = useState();
  const [productCount, setProductCount] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const [pId, setPId] = useState([]);
  const [size, setSize] = useState([]);
  const [qty1, setQty1] = useState([]);
  const [pKey, setPKey] = useState("");
  const [dataChange, setDataChange] = useState([]);
  const [total, setTotal] = useState();
  const [modal, setModal] = useState(false);
  const [artistId, setArtistId] = useState();
  const [showModal, setShowModal] = useState({
    open: false,
    amount: null,
  });

  let tl = 0;
  useEffect(() => {
    const getAllCartProduct = async () => {
      await ApiGet("cart/get-cart")
        .then((res) => {
          console.log(res?.data?.data[0]?.artistId);
          setCartProducts(res?.data?.data);
          setCartLength(res?.data?.data?.length);
          setArtistId(res?.data?.data[0]?.artistId);
          res.data.data.map((data) => {
            setPId((arr) => [...arr, data?.productId]);
            setSize((arr1) => [...arr1, data?.size]);
            setQty1((arr2) => [...arr2, data?.quantity]);
          });

          for (let i = 0; i < res?.data?.data?.length; i++) {
            tl = tl + res?.data?.data[i]?.price;
            setTotal(tl);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    };
    const handleLoginCheck = () => {
      //console.log(authUtil.isLoggedIn());
      if (authUtil.isLoggedIn() === true) {
        setModal(true);
      } else {
        history("/shop");
      }
    };

    handleLoginCheck();

    getAllCartProduct();
  }, [dataChange]);

  const handleProductCount = (countData, price, qty, key, pid) => {
    setPKey(key);

    if (countData === "plus") {
      setProductPrice(price + price / qty);
      setProductCount(qty + 1);
      handleAddToCart(pid, qty + 1);
    } else if (countData === "minus" && qty >= 2) {
      setProductPrice(price - price / qty);
      setProductCount(qty - 1);
      handleAddToCart(pid, qty - 1);
    } else {
      setProductCount(1);
    }
  };

  const handleDeleteProductCart = async (id) => {
    //console.log(id);
    await ApiDelete(`cart/${id}`)
      .then((res) => {
        //console.log(res.data);
        setDataChange([...dataChange, "product removed"]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAddToCart = async (id, qty) => {
    //console.log(qty);
    //setQty1((arr2) => [...arr2, qty]);
    const data = {
      productId: id,
      quantity: qty,
    };
    console.log(data);
    await ApiPut("cart/update-cart", data)
      .then((res) => {
        //console.log(res.data);
        setPKey("");
        setSize([]);
        setPId([]);
        setQty1([]);
        // console.log(qty1);
        setDataChange([...dataChange, "product removed"]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {modal ? (
        <div>
          {/* {
            (console.log("Product Id Array ", pId),
            console.log("Product size array ", size),
            console.log("Product qty", qty1))
          } */}
          <>
            <Header />
          </>
          <div className="flex">
            <>
              <Sidebar />
            </>
            <main className="s-layout__content">
              <div className="main-box">
                <div className="container-fluid">
                  <div className="md:flex justify-center pt-5">
                    <section className="pt-6">
                      {/* <section className="pt-16"> Dont forget to replace when shop menu opens  */}
                      <div className="container-fluid pt-12">
                        <div className="md:flex">
                          <div className="md:w-full">
                            <p className="green-text-color adobe-font font-size-40 font-bold cursor-pointer tracking-normal text-center">
                              Shop Cart Coming Soon...
                            </p>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      ) : null}
      {showModal.open ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div
                  className="flex items-start justify-between p-5 border-b border-solid border-white-100 rounded-t-lg"
                  style={{ backgroundColor: "#090C10" }}
                >
                  <h3 className="text-2xl font-bold text-white">
                    Purchase this product
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-white  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() =>
                      setShowModal({
                        ...showModal,
                        open: false,
                      })
                    }
                    OnTouchStart={() =>
                      setShowModal({
                        ...showModal,
                        open: false,
                      })
                    }
                  >
                    <p>
                      <i className="fas fa-times"></i>
                    </p>
                  </button>
                </div>
                {/*body*/}
                <div
                  className="relative p-6 flex-auto rounded-b-lg"
                  style={{ backgroundColor: "#090C10" }}
                >
                  <p className="mb-3 text-white text-lg leading-relaxed">
                    Purchase this products now.
                  </p>
                  {/* <p className="mb-3 text-white text-lg leading-relaxed">
                    product name: {showModal.musicTitle}
                  </p> */}
                  <p className="mb-3 text-white text-lg leading-relaxed">
                    Subtotal: {showModal.amount}$
                  </p>
                  <Elements stripe={stripePromise}>
                    <Payments
                      productId={pId}
                      amount={showModal.amount}
                      size={size}
                      productArtistId={artistId}
                      quantity={qty1}
                    />
                  </Elements>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
