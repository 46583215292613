/* jshint esversion: 6 */
/* jshint esversion: 8 */
/* jshint esversion: 11 */
import React, { useEffect, useState } from "react";
import "../../js/Trending/trending.scss";
import "../../../Components/styles/dashboard.scss";
import Sidebar from "../../Layout/Sidebar";
import Header from "../../Layout/Header";
import { NavLink, useNavigate } from "react-router-dom";
import { ApiGet, ApiPost, ApiPut } from "../../../helpers/API/ApiData";
import * as userUtils from "../../../utils/user.util";

const MyOrders = () => {
  const userId = userUtils.getUserInfo().user.id;
  const history = useNavigate();

  const [orders, setOrders] = useState();
  const [loader, setLoader] = useState(false);
  const [showOrder, setShowOrder] = useState("pending");
  const [datachange, setDatachange] = useState([]);
  const [cancelOrder, setCancelOrder] = useState({
    open: false,
    userId: null,
    orderId: null,
    status: null,
  });
  const [returnOrder, setrReturnOrder] = useState({
    open1: false,
    userId1: null,
    orderId1: null,
    status1: null,
  });
  console.log(cancelOrder);
  useEffect(() => {
    const getAllMyOrder = async () => {
      setLoader(true);
      await ApiGet("order/get-user-orders")
        .then((res) => {
          setLoader(false);
          console.log(res.data.data);
          setOrders(res.data.data);
        })
        .catch((err) => {
          setLoader(false);
          console.log(err);
        });
    };
    getAllMyOrder();
  }, [datachange]);

  const handleOrderStatus = async () => {
    const data = {
      id: cancelOrder.orderId,
      status: parseInt(cancelOrder.status),
      userId: cancelOrder.userId,
    };
    console.log(data);
    await ApiPut("order/update-status", data)
      .then((res) => {
        console.log(res);
        setDatachange([...datachange, "return"]);
        setCancelOrder({ open: false });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleOrderStatusReturn = async () => {
    const data = {
      id: returnOrder.orderId1,
      status: parseInt(returnOrder.status1),
      userId: returnOrder.userId1,
    };
    console.log(data);
    await ApiPut("order/update-status", data)
      .then((res) => {
        console.log(res);
        setDatachange([...datachange, "return"]);
        setrReturnOrder({ open1: false });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getNewDate = (date) => {
    var d = new Date(date);
    var month = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var newDate = `${month[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
    return newDate;
  };
  console.log(orders);
  return (
    <>
      <div>
        <>
          <Header />
        </>
        <div className="flex">
          <>
            <Sidebar />
          </>
          <main className="s-layout__content">
            <div className="main-box">
              <div className="container-fluid">
                <div className="md:flex">
                  <div className="md:w-full">
                    <div className="pt-5 flex items-center">
                      <NavLink to="/" onClick={(e) => {
                      e.preventDefault();
                      history(-1)
                    }}>
                        <p>
                          <i className="fas fa-arrow-left cursor-pointer icon-font-size text-white"></i>
                        </p>
                      </NavLink>
                      <p className="pl-3 font-size-35 white-text-color font-medium tracking-normal text-center mb-0">
                        My Orders
                      </p>
                    </div>
                  </div>
                </div>

                <div className="md:flex justify-center pt-5">
                  <div className="md:w-5/6">
                    <div className="cart-table ">
                      <div className="flex justify-center mb-10">
                        <h1 className="text-3xl text-white font-bold center">
                          Pending orders
                        </h1>
                      </div>
                      {loader ? (
                        <div className="flex justify-end">
                          <div className="dots">
                            <div className="dot"></div>
                            <div className="dot"></div>
                            <div className="dot"></div>
                          </div>
                        </div>
                      ) : orders?.length === 0 ? (
                        <div className="text-white text-3xl ml-10 z-40 flex justify-center">
                          <h1>No data found :(</h1>
                        </div>
                      ) : (
                        <table width="100%" className="cart-table1">
                          <tr>
                            <th align="center">
                              <span className="table-header font-size-20 font-medium tracking-normal white-text-color">
                                Item
                              </span>
                            </th>
                            <th align="center">
                              <span className="table-header font-size-20 font-medium  white-text-color">
                                Status
                              </span>
                            </th>
                            <th align="center">
                              <span className="table-header font-size-20  font-medium ml-0 mr-0 white-text-color">
                                Size
                              </span>
                            </th>
                            <th align="center">
                              <span className=" table-header font-size-20 ml-10 font-medium tracking-normal white-text-color">
                                Price
                              </span>
                            </th>
                            <th align="center">
                              <span className="table-header font-size-20 ml-20 font-medium tracking-normal white-text-color">
                                Quantity
                              </span>
                            </th>
                            <th align="center">
                              <span className="table-header font-size-20 ml-20 font-medium tracking-normal white-text-color">
                                Date
                              </span>
                            </th>
                            <th align="center">
                              <span className="table-header font-size-20 ml-20 font-medium tracking-normal white-text-color">
                                Action
                              </span>
                            </th>
                          </tr>

                          {orders &&
                            orders
                              ?.slice(0)
                              .reverse()
                              .map((data, key) => {
                                return (
                                  <>
                                    {data.productCategory === "product"
                                      ? data?.orderDetail?.map(
                                          (orderData, key) => {
                                            return (
                                              <>
                                                <tr className="pt-4 ">
                                                  <td className="cursor-pointer">
                                                    <NavLink
                                                      to={`/cart/${orderData.productId}`}
                                                    >
                                                      <div className="flex justify-center">
                                                        <div className="cart-select-img flex justify-center">
                                                          <img
                                                            src={
                                                              orderData.image
                                                            }
                                                            alt="product"
                                                          />
                                                        </div>
                                                      </div>
                                                      <div className="pl-5 pt-3">
                                                        <p className="font-size-14 cursor-pointer font-medium tracking-normal white-text-color mb-0">
                                                          {orderData.title}
                                                        </p>
                                                      </div>
                                                    </NavLink>
                                                  </td>
                                                  <td>
                                                    <span className="font-size-18 white-text-color flex justify-center font-medium tracking-normal text-center">
                                                      {orderData.status}
                                                    </span>
                                                  </td>
                                                  <td>
                                                    <span className="font-size-18 white-text-color flex justify-center font-medium tracking-normal text-center">
                                                      {orderData.size}
                                                    </span>
                                                  </td>
                                                  <td>
                                                    <span className="font-size-18 white-text-color ml-10 flex justify-center font-medium tracking-normal text-center">
                                                      {orderData.price *
                                                        orderData.quantity}
                                                    </span>
                                                  </td>

                                                  <td>
                                                    <span className="font-size-18 tracking-normal ml-20 font-medium white-text-color">
                                                      {orderData.quantity}
                                                    </span>
                                                  </td>
                                                  <td>
                                                    <span className="font-size-18 tracking-normal ml-20 font-medium white-text-color">
                                                      {getNewDate(
                                                        data.createdAt
                                                      )}
                                                    </span>
                                                  </td>
                                                  <td>
                                                    <span className="font-size-18 white-text-color ml-20 flex justify-center font-medium tracking-normal text-center">
                                                      {orderData.status ===
                                                        "Delivered" ||
                                                      orderData.status ===
                                                        "Accepted" ||
                                                      orderData.status ===
                                                        "New Order" ? (
                                                        <button
                                                          className="bg-red-600 font-medium tracking-normal white-text-color text-center cursor-pointer px-2 rounded"
                                                          onClick={() =>
                                                            setCancelOrder({
                                                              open: true,
                                                              userId: userId,
                                                              orderId:
                                                                orderData._id,
                                                              status: 7,
                                                            })
                                                          }
                                                          OnTouchStart={() =>
                                                            setCancelOrder({
                                                              open: true,
                                                              userId: userId,
                                                              orderId:
                                                                orderData._id,
                                                              status: 7,
                                                            })
                                                          }
                                                        >
                                                          Cancel
                                                        </button>
                                                      ) : (
                                                        orderData.status ===
                                                          "Completed" && (
                                                          <button
                                                            className="bg-red-600 font-medium tracking-normal white-text-color text-center cursor-pointer px-2 rounded"
                                                            onClick={() =>
                                                              setrReturnOrder({
                                                                open1: true,
                                                                userId1: userId,
                                                                orderId1:
                                                                  orderData._id,
                                                                status1: 6,
                                                              })
                                                            }
                                                            OnTouchStart={() =>
                                                              setrReturnOrder({
                                                                open1: true,
                                                                userId1: userId,
                                                                orderId1:
                                                                  orderData._id,
                                                                status1: 6,
                                                              })
                                                            }
                                                          >
                                                            Return
                                                          </button>
                                                        )
                                                      )}
                                                    </span>
                                                  </td>
                                                </tr>
                                              </>
                                            );
                                          }
                                        )
                                      : null}
                                  </>
                                );
                              })}
                        </table>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>

      {cancelOrder.open ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*body*/}
                <div
                  className="px-20 pb-10 pt-5 flex flex-col items-center justify-center rounded-lg"
                  // style={{ backgroundColor: "#090C10" }}
                >
                  <p className="pt-3 px-10 pb-3 font-size-35 white-text-color font-medium tracking-normal text-center mb-0">
                    Are you sure want to cancel this order?
                  </p>

                  <div className="flex pt-3">
                    <button
                      className="text-red-500 border border-solid border-green rounded font-bold uppercase px-6 py-2 text-sm  mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => setCancelOrder({ open: false })}
                      OnTouchStart={() => setCancelOrder({ open: false })}

                    >
                      Close
                    </button>
                    <button
                      className="text-green-500 border border-solid border-green rounded font-bold uppercase px-6 py-2 text-sm  mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => handleOrderStatus()}
                      OnTouchStart={() => handleOrderStatus()}

                    >
                      Cancel order
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
      {returnOrder.open1 ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*body*/}
                <div
                  className="px-20 pb-10 pt-5 flex flex-col items-center justify-center rounded-lg"
                  style={{ backgroundColor: "#061E1D" }}
                >
                  <p className=" px-10 pb-3 font-size-35 white-text-color font-medium tracking-normal text-center mb-0">
                    Are you sure want to return this order
                  </p>

                  <div className="flex">
                    <button
                      className="text-red-500 border border-solid border-green rounded font-bold uppercase px-6 py-2 text-sm  mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => setrReturnOrder({ open1: false })}
                      OnTouchStart={() => setrReturnOrder({ open1: false })}

                    >
                      Close
                    </button>
                    <button
                      className="text-green-500 border border-solid border-green rounded font-bold uppercase px-6 py-2 text-sm  mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => handleOrderStatusReturn()}
                      OnTouchStart={() => handleOrderStatusReturn()}

                    >
                      return order
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default MyOrders;
