/* jshint esversion: 6 */
/* jshint esversion: 8 */
/* jshint esversion: 11 */

import React, { useState, useEffect } from "react"; 
import { Link, NavLink, useNavigate } from "react-router-dom"; 
import "../styles/main.scss";
import "../Layout/layout.scss";  
import { ApiPost } from "../../helpers/API/ApiData";
import * as authUtil from "../../utils/auth.util";
import * as userUtil from "../../utils/user.util"; 
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

const token = window.location.search.slice(1);

export default function Header() {
  const history = useNavigate();
  const logoutUser = () => {
    authUtil.logout();
    history("/");
    window.location.reload();
  };
  const user = userUtil.getUserInfo();
  //6console.log(user)
  const [loading, setLoading] = useState(false); 
  const [errors, setErrors] = useState([]); 
  const [formData, setFormData] = useState({});  
  const [ChangeNewpassworrd, setChangeNewpassworrd] = useState();
  const [Changeconformpassworrd, setChangeconformpassworrd] = useState();
  const [notmatch, setnotmatch] = useState();
  const [changepass, setchangepass] = useState();
  const [confirm, setconform] = useState();
  const [strogpassword, setstrogpassword] = useState(); 
  const profilePicture = useSelector((state) => state.ProfilePicture); 
  const chnageSubmit = async () => {
    if (ChangeNewpassworrd !== Changeconformpassworrd) {
      console.log("password does not match");
      setnotmatch("Password does not match");
      setstrogpassword("");
    } else if ((ChangeNewpassworrd === "") & (Changeconformpassworrd === "")) {
      setchangepass("Please Enter the New Password ");
      setconform("please Enter the confirm Password");
    } else if (ChangeNewpassworrd === "") {
      setchangepass("Please Enter the New Password ");
    } else if (Changeconformpassworrd === "") {
      setconform("Please Enter the confirm Password");
    } else if (
      !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{7,15}$/.test(
        ChangeNewpassworrd
      )
    ) {
      setstrogpassword(
        "Password does not meet requirements. Please choose a stronger password"
      );
      setnotmatch("");
    } else {
      const data = {
        newPassword: ChangeNewpassworrd,
        token: token,
      };

      await ApiPost("users/change-password", data)
        .then((res) => {
          console.log("forgotpassword", res.data.message);
          if (res.data.message === "PASSWORD RESET SUCCESSFULLY..!") {
            history("/");
            setnotmatch("");
            setchangepass("");
            setconform("");
            setstrogpassword("");
            toast.success(res.data.message);
          } else {
            toast.error(res.data.data);
            setnotmatch("");
            setchangepass("");
            setconform("");
            setstrogpassword("");
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log("err", err);
        });
    }
  };

  const validate = () => {
    let error = [];
    let formIsValid = true;

    if (!formData.email) {
      formIsValid = false;
      error["email"] = "Please Enter Email";
    }
    if (!formData.password) {
      formIsValid = false;
      error["password"] = "Please Enter Password";
    }
    setErrors(error);
    return formIsValid;
  };

  return (
    <div>
      <header>
        <div className="container-fluid-one"> 
          <>
            <div>
              <div className="animation justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-full my-6 mx-auto lg:w-5/12 md:w-4/5 sm:w-1/2">
                  {/*content*/}
                  <div className="relative modal-login-box border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                    {/*header*/}
                    <div className="flex items-start justify-between p-5 rounded-t">
                      <h3 className="text-2xl font-bold text-white"> </h3>

                      <button className="p-1 ml-auto bg-transparent border-0 text-white  float-right text-3xl leading-none font-semibold outline-none focus:outline-none">
                        <Link to="/">
                          <p
                            onClick={() => {
                              setnotmatch("");
                              setchangepass("");
                              setconform("");
                              setstrogpassword("");
                            }}
                            OnTouchStart={() => {
                              setnotmatch("");
                              setchangepass("");
                              setconform("");
                              setstrogpassword("");
                            }}
                          >
                            <i className="fas fa-times login-text-color relative"></i>
                          </p>
                        </Link>
                      </button>
                    </div>
                    {/*body*/}

                    <div className="relative p-6 flex-auto end-text">
                      <div>
                        <div className="">
                          <div>
                            <div className="login_logo pl-5 pr-5 flex justify-center relative">
                              <img
                                src={
                                  require("../../Assets/Images/login-logo.png")
                                    .default
                                }
                                alt=""
                              />
                            </div>
                            <h6 className="text-center">
                              <span
                                style={{
                                  color: "white",
                                }}
                              >
                                {" "}
                                Pick a new password
                              </span>
                            </h6>
                          </div>
                        </div>
                        <p className="text-center font-size-14 pt-12">
                          <span
                            style={{
                              color: "red",
                            }}
                          >
                            {notmatch}
                            {strogpassword}
                          </span>
                        </p>
                        <div className="flex flex-col items-left justify-center login-placeholder pt-5">
                          <div className="">
                            <span
                              style={{
                                color: "red",
                                top: "0px",
                                fontSize: "12px",
                              }}
                            >
                              {changepass}
                            </span>
                            <input
                              type="password"
                              name="password"
                              className="form-control-login mb-8"
                              placeholder="Enter New password"
                              onChange={(e) =>
                                setChangeNewpassworrd(e.target.value)
                              }
                              // onKeyUp={(event) => handleSubmit(event)}
                            />
                          </div>
                          <div className="">
                            <span
                              style={{
                                color: "red",
                                top: "5px",
                                fontSize: "12px",
                              }}
                            >
                              {confirm}
                            </span>
                            <input
                              type="password"
                              name="password"
                              className="form-control-login mb-8"
                              placeholder="Enter confirm password"
                              onChange={(e) =>
                                setChangeconformpassworrd(e.target.value)
                              }
                              // onKeyUp={(event) => handleSubmit(event)}
                            />{" "}
                          </div>
                        </div>
                        <div>
                          <div className="flex justify-center">
                            <button
                              className="login-modal-btn font-size-16 white-text-color font-normal tracking-normal cursor-pointer"
                              onClick={() => chnageSubmit()}
                              OnTouchStart={() => chnageSubmit()}
                              type="submit"
                            >
                              {" "}
                              submit
                            </button>
                          </div>
                          <div>
                            <p className="font-size-18 white-text-color font-normal tracking-normal mb-0 text-center mt-4"></p>
                          </div> 
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </div>
          </> 
        </div>
      </header>
    </div>
  );
}
